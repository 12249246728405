"use strict";
import('./App');
const head = document.querySelector('head');
const link = document.createElement('link');
link.href = 'https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap';
link.rel = 'stylesheet';
if (head)
    head.appendChild(link);
const body = document.querySelector('body');
if (body) {
    body.style.height = '100vh';
    body.style.width = '100vw';
    body.style.margin = '0';
    body.style.padding = '0';
    body.style.fontFamily = 'Roboto';
    body.style.fontSize = '16px';
}
const app = document.createElement('mm-showcase');
document.body.appendChild(app);
